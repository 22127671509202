<template>
  <b-modal id="modal-tryagain" title="ส่งข้อมูลผิดพลาด" ok-only>
    <p class="my-2">กรุณาลองใหม่อีกครั้ง</p>
  </b-modal>
</template>

<script>
export default {};
</script>

<style></style>
